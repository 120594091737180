<template>
  <div class="reward-small-card-component">
    <nuxt-link
      v-slot="{ navigate }"
      custom
      :to="{ name: route, params: { id: id || item.id } }"
    >
      <div
        class="reward-small-card"
        :class="{ notForYou }"
        @click="
          disabled || notForYou || (isCoupon && unavailable && !gotActiveItem)
            ? ''
            : navigate()
        "
      >
        <div class="relative">
          <div
            class="reward-small-card-img"
            :class="{ unavailable: unavailable && !gotActiveItem }"
          >
            <div class="image-container">
              <LazyLazyImage :src="thumbnailUrl" />
              <VPlus
                v-if="exclusiveDeal"
                class="absolute left-0 h-6 mt-2 ml-2"
              />
              <RewardCountdown
                class="absolute right-0 mt-2 mr-3"
                :target="item.visible_to"
              />
            </div>
            <div v-if="unavailable && !gotActiveItem" class="missed-label">
              <template v-if="isCoupon">
                <p
                  v-if="missed && !gotItem"
                  v-t="{ path: 'components.rewards.treasurehunt.sold_out' }"
                  class="missed-label-items"
                />

                <p
                  v-else-if="gotItem"
                  v-t="{
                    path: 'components.rewards.treasurehunt.user_sold_out',
                  }"
                  class="missed-label-items"
                />

                <p v-if="notForYou" class="missed-label-items">
                  <span v-t="{ path: 'components.rewards.exclusive_deal' }" />
                  <EssentialsDefaultButtonEvent
                    class="mt-1 button vplus-style"
                    :small="true"
                    :large-text="false"
                    @click="openLink"
                  >
                    {{ $t('components.rewards.subscribe') }}
                  </EssentialsDefaultButtonEvent>
                </p>
              </template>
              <template v-else>
                <p
                  v-if="missed"
                  v-t="{ path: 'components.rewards.sold_out' }"
                  class="missed-label-items"
                />
                <p v-else-if="notForYou" class="missed-label-items">
                  <span v-t="{ path: 'components.rewards.exclusive_deal' }" />
                  <EssentialsDefaultButtonEvent
                    class="mt-1 button vplus-style"
                    :small="true"
                    :large-text="false"
                    @click="openLink"
                  >
                    {{ $t('components.rewards.subscribe') }}
                  </EssentialsDefaultButtonEvent>
                </p>
                <p
                  v-else
                  v-t="{ path: 'components.rewards.available_shortly' }"
                  class="missed-label-items"
                />
              </template>
            </div>
          </div>
        </div>

        <div class="reward-small-card-title">
          <p class="p-2">
            {{ item.title }}
          </p>
        </div>
        <div v-if="!hideDetails" class="reward-small-card-details">
          <p class="type">
            {{ $t('components.rewards.reward_types_card.' + type) }}
          </p>
          <div class="reward-small-card-price">
            <p class="price">
              {{ item.price.toLocaleString('de-DE') }}
            </p>
            <LazyLazyImage
              v-if="meStore.currency.media.length > 0"
              :src="meStore.currency.media[0].conversions.thumb"
            />
          </div>
        </div>

        <div v-if="isFund" class="mx-2">
          <RewardsFundProgress
            class="progress"
            :value="item.fund > 10 ? item.fund : 10"
          ></RewardsFundProgress>

          <span class="text-sm">{{
            $t('components.rewards.fund', {
              currency: meStore.currencyNameTranslated(this.$t),
            })
          }}</span>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideLabels: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      default: 'me-rewards-detail-id',
    },
    id: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    ...mapStores(useMeStore, useMainStore),
    imageUrl() {
      if (
        this.item.media.title_small.length > 0 &&
        this.item.media.title_small[0].conversions
      ) {
        return this.item.media.title_small[0].conversions.small;
      }
      // Title fallback
      if (
        this.item.media.title.length > 0 &&
        this.item.media.title[0].conversions
      ) {
        return this.item.media.title[0].conversions.small;
      }
      return '';
    },
    thumbnailUrl() {
      if (this.item.redeem_possible_by_time === 'from_date') {
        if (
          this.item.media.title_small_disabled.length > 0 &&
          this.item.media.title_small_disabled[0].conversions
        ) {
          return this.item.media.title_small_disabled[0].conversions.small;
        }
      }
      return this.imageUrl;
    },
    type() {
      if (
        this.item.payment_type === 'none' &&
        this.item.delivery_type === 'external'
      ) {
        return 'raffle';
      }
      if (this.exclusiveDeal) {
        return `exclusiveDeal_${this.item.payment_type}`
      }
      return this.item.payment_type;
    },
    missed() {
      return (
        !this.item.items_left || this.item.redeem_possible_by_time === 'to_date'
      );
    },
    available_shortly() {
      if (this.isCoupon) {
        return this.item.redeem_possible_by_time === 'from_date';
      }

      if (this.item.media.title_small_disabled.length === 0) {
        return this.item.redeem_possible_by_time === 'from_date';
      }
      return false;
    },
    showLabel() {
      return this.missed || this.available_shortly;
    },
    unavailable() {
      return (this.showLabel && !this.hideLabels) || this.notForYou;
    },
    isPremiumUser() {
      return this.item.subs_redeemable;
    },
    isFund() {
      return this.item.payment_type === 'fund';
    },
    exclusiveDeal() {
      return this.item.subs_only;
    },
    notForYou() {
      return this.exclusiveDeal && !this.isPremiumUser;
    },
    gotItem() {
      return Object.hasOwnProperty.call(this.item, 'reward_item_id');
    },
    gotActiveItem() {
      return Date.parse(this.item.reward_item_available_to) > Date.now();
    },
    isCoupon() {
      return this.item.delivery_type === 'coupon';
    },
    availableFrom() {
      const date = new Date(Date.parse(this.item.available_from));
      return date.toLocaleString('de-DE', { month: '2-digit', day: '2-digit' });
    },
  },

  methods: {
    openLink() {
      this.mainStore.openExternalLink(
        'https://www.vol.at/abo-vplus?utm_source=Vplus_Rewards&utm_medium=Vplus_Rewards&utm_campaign=vplus_ExclusiveReward&utm_term=vplus_ExclusiveReward_Spezialseite'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-small-card-component {
  @apply w-full max-w-48 block cursor-pointer;
}

.reward-small-card {
  @apply bg-white rounded-lg shadow-card w-full pb-2;
}

.reward-small-card-img {
  @apply h-48 overflow-hidden rounded-t-lg;
}

.image-container {
  @apply flex justify-center h-full relative;

  img {
    @apply h-full absolute object-cover;
  }
}

.unavailable {
  img {
    filter: brightness(0.6);
  }
}

.missed-label {
  @apply relative -mt-48 w-full h-48 flex;


}

.missed-label-items {
    @apply text-white text-3xl m-auto px-2 text-center font-black;
}

.reward-small-card-title {
  @apply text-lg text-theme-primary font-black h-12 overflow-hidden;
}

.reward-small-card-details {
  @apply flex justify-between p-2;
}

.reward-small-card-price {
  @apply flex justify-end;

  p {
    @apply pr-1;
  }

  img {
    @apply h-8 -mt-2;
  }
}

.notForYou {
  @apply bg-gray-400;

  img {
    filter: brightness(0.5);
  }
}

.components-essentials-defaultbuttonevent-container.vplus-style {
  @apply w-full border-white text-white;
  background: #e3051b;
}

.components-essentials-defaultbuttonevent-container.vplus-style:hover {
  @apply bg-white;
  color: #e3051b;
  border-color: #e3051b;
}
</style>
